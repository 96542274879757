<template>
  <div class="input-group">
    <IonItem v-if="label" lines="none" class="ion-no-margin ion-no-padding">
      <IonLabel>{{ label }}</IonLabel>
    </IonItem>
    <IonTextarea :rows="rows" v-bind="$attrs" v-if="type === 'textarea'" class="default-input"></IonTextarea>
    <IonSelect
        interface="popover"
        v-bind="$attrs"
        v-else-if="type === 'select'"
        :value="label"
        class="default-input default-select-input"
        :placeholder="label"
        :cancelText="t('messages.recipients.cancel')"
        :okText="t('messages.recipients.apply')"
    >
      <IonSelectOption
          v-for="option in options"
          :key="option.value"
          :value="option.value"
      >{{ t(option.label) }}
      </IonSelectOption
      >
    </IonSelect>
    <IonInput v-else v-bind="$attrs" :type="type" class="default-input"></IonInput>
  </div>
</template>

<script>
import {IonInput, IonTextarea, IonSelect, IonSelectOption, IonLabel, IonItem } from '@ionic/vue';
import {defineComponent} from "vue";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: 'InputGroup',
  components: {
    IonInput,
    IonTextarea,
    IonSelect,
    IonItem,
    IonSelectOption,
    IonLabel,
  },
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
    },
    rows: {
      type: String,
      default: '5'
    },
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  setup() {
    const {t} = useI18n()

    return {
      t,
    }
  },
});
</script>

<style scoped lang="scss">
.input-group {
  ion-label {
    margin-bottom: 15px;
  }
}
label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 5px;
  display: block;
  color: var(--ion-color-primary);
}

.default-input {
  background: #f1f1f1;
  border: 1px solid #ccc;
  color: #000;
  border-radius: 5px;
  padding: 5px 15px !important;

  &.default-select-input {
    height: 42px;
  }
}
</style>
