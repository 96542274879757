
import { defineComponent, ref } from "vue";
import {
  IonAvatar,
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonFab,
  IonFabButton,
  IonIcon,
  IonListHeader,
  toastController,
  isPlatform
} from "@ionic/vue";
import { addOutline } from "ionicons/icons";
import Container from "../layout/Container.vue";
import ApiService from "../../services/api.service";
import mixin from "@/mixin";
import { Patient, PatientExercise } from "@/interfaces/patients";
import { useI18n } from "vue-i18n";
import Modal from "@/components/Modal.vue";
import Upload from "@/components/Upload.vue";
import Button from "@/components/Button.vue";
import InputGroup from "@/components/InputGroup.vue";
import ExerciseForm from "@/components/patients/ExerciseForm.vue";
import Alert from "@/components/Alert.vue";
import Notes from "@/components/notes/List.vue";
import { useRouter } from "vue-router";
import Loading from "@/components/Loading.vue";
import moment, { Moment } from "moment";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "patient",

  components: {
    Loading,
    Alert,
    ExerciseForm,
    Modal,
    Upload,
    Button,
    InputGroup,
    Container,
    Notes,
    IonAvatar,
    IonCard,
    IonListHeader,
    IonCardContent,
    IonList,
    IonItem,
    IonLabel,
    IonModal,
    IonRow,
    IonCol,
    IonSegment,
    IonSegmentButton,
    IonFab,
    IonFabButton,
    IonIcon
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const displayList = ref(null) as any;
    const exerciseCreateForm = ref(null) as any;
    const exerciseEditForm = ref(null) as any;
    const showNewExercise = ref(false);
    const openExerciseModal = (state: boolean) =>
      (showNewExercise.value = state);
    const showEditExercise = ref(false);
    const openEditExerciseModal = (state: boolean) =>
      (showEditExercise.value = state);

    const showNotesModal = ref(false);
    const openNotesModal = (state: boolean) => showNotesModal.value = state;
    const uploadRef = ref(Upload);

    return {
      t,
      router,
      displayList,
      exerciseCreateForm,
      exerciseEditForm,
      showNewExercise,
      openExerciseModal,
      showEditExercise,
      openEditExerciseModal,
      addOutline,
      showNotesModal,
      openNotesModal,
      uploadRef,
    };
  },
  data() {
    return {
      patient: {} as Patient,
      notes: [],
      newNote: '',
      exerciseToEdit: 0,
      loading: true,
      loadingNotes: false,
      tabToShow: "exercises",
      dates: [] as Moment[]
    };
  },
  computed: {
    ...mapGetters({
      locale: "app/locale"
    }),
    ...mapGetters("permission", ["getPermission"]),
    exerciseData(): PatientExercise | null {
      return this.exerciseToEdit !== null
        ? this.patient.exercises[this.exerciseToEdit]
        : null;
    },
    isTablet() {
      return isPlatform("ipad") || isPlatform("tablet");
    }
  },

  mixins: [mixin],

  watch: {
    "$route.path"() {
      this.initialise();
    }
  },

  methods: {
    ionViewWillEnter() {
      this.dates = [
        moment().locale(this.locale),
        moment()
          .locale(this.locale)
          .add(1, "days"),
        moment()
          .locale(this.locale)
          .add(2, "days"),
        moment()
          .locale(this.locale)
          .add(3, "days"),
        moment()
          .locale(this.locale)
          .add(4, "days"),
        moment()
          .locale(this.locale)
          .add(5, "days"),
        moment()
          .locale(this.locale)
          .add(6, "days")
      ];

      this.initialise();
    },

    initialise() {
      this.getPatient();
      this.displayList?.getPatients();
    },

    async getPatient() {
      if (!this.$route.params.id) {
        return;
      }

      this.loading = true;
      const { data } = await ApiService.get(
        `/admin/patient/${this.$route.params.id}`
      );

      this.patient = data;
      this.loading = false;
    },
    async exerciseCreated() {
      this.openExerciseModal(false);

      const toast = await toastController.create({
        message: this.t("patients.exerciseCreated"),
        duration: 3000
      });

      this.getPatient();
      await toast.present();
    },
    async exerciseUpdated() {
      this.openEditExerciseModal(false);

      const toast = await toastController.create({
        message: this.t("patients.exerciseUpdated"),
        duration: 3000
      });

      this.getPatient();
      await toast.present();
    },
    async exerciseDeleted() {
      this.openEditExerciseModal(false);

      const toast = await toastController.create({
        message: this.t("patients.exerciseDeleted"),
        duration: 3000
      });

      this.getPatient();
      await toast.present();
    },
    editExercise(index: number) {
      this.exerciseToEdit = index;
      this.openEditExerciseModal(true);
    },
    async tabChanged(ev: CustomEvent) {
      this.tabToShow = ev.detail.value;

      if (this.tabToShow === 'notes') {
        this.loadingNotes = true;

        await this.getNotes();

        this.loadingNotes = false;
      }
    },
    open() {
      if (this.tabToShow === "exercises") {
        this.openExerciseModal(true);
      }

      if (this.tabToShow === "notes") {
        this.openNotesModal(true);
      }
    },

    sendMessage() {
      ApiService.post(`/admin/patient/${this.$route.params.id}/notepad`, {
        message: this.newNote,
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_private: false,
        // eslint-disable-next-line @typescript-eslint/camelcase
        upload_identifiers: this.uploadRef.completedUploads.map((object: any) => {
          return object.fileId;
        }),
      }).then(() => {
        this.newNote = '';
        this.openNotesModal(false);
        this.uploadRef.completedUploads = [];
        this.getNotes();
      })
    },

    async getNotes() {
      if (this.getPermission("View_notepad")) {
        const { data: notes } = await ApiService.get(
          `/admin/patient/${this.$route.params.id}/notepad`
        );
        this.notes = notes.results;
      } else {
        this.notes = [];
      }
    },
  },
  created() {
    this.getPatient();
  }
});
