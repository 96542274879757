<template>
  <IonCard>
    <IonCardHeader v-if="title">
      <IonCardTitle>{{ title }}</IonCardTitle>
    </IonCardHeader>
    <IonCardContent class="notes-card">
      <div v-if="getPermission('generalInstructions.instructions')" class="notes-instruction">
        <p>
          {{ getPermission('generalInstructions.instructions') }}
        </p>
      </div>
      <Loading v-show="loading" />
      <div v-if="records">
        <IonList v-for="record in records" :key="record.id" class="note">
          <IonItemSliding>
            <IonItem class="note-item" @click="router.push(`/notes/${record.id}`)">
              <IonAvatar slot="start" class="notes-avatar">
                <img loading="lazy" :src="record.creator.profile_picture">
              </IonAvatar>
              <IonLabel class="notes-message">
                <h2>{{ record.creator.full_name }}</h2>
                <p>
                  {{ getMessagePreview(record.body) }}
                </p>
                <p>{{ moment(record.updated_at, 'DD-MM-YYYY H:mm').locale(locale).format('D MMMM (HH:mm)') }}</p>
              </IonLabel>
            </IonItem>
          </IonItemSliding>
        </IonList>
      </div>
    </IonCardContent>
  </IonCard>
</template>

<script type="ts">
import {
  IonList,
  IonItem,
  IonItemSliding,
  IonLabel,
  IonCard,
  IonCardContent,
  IonAvatar,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/vue'
import {useRouter} from 'vue-router';
import {defineComponent} from "vue";
import moment from "moment";
import {useI18n} from "vue-i18n";
import {mapActions, mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";

export default defineComponent({
  name: 'List',
  components: {
    Loading,
    IonList,
    IonItem,
    IonItemSliding,
    IonLabel,
    IonCard,
    IonCardContent,
    IonAvatar,
    IonCardHeader,
    IonCardTitle,
  },
  props: {
    records: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    title: String,
  },
  setup() {
    const router = useRouter();
    const {t} = useI18n();

    return {
      t,
      router,
      moment,
    };
  },
  computed: {
    ...mapGetters("permission", ["getPermission"]),
    ...mapGetters({
      locale: 'app/locale',
    }),
  },
  methods: {
    ...mapActions("permission", ["fetchPermission"]),
    getMessagePreview(message) {
      const div = document.createElement('div');
      div.innerHTML = message;

      const cleanText = div.textContent;

      return cleanText.substr(0, 100);
    },
  },

  created() {
    this.fetchPermission();
  }
});
</script>

<style lang="scss">
.notes-card {
  padding: 0 15px 5px;
}

.note {
  margin: 0;
  border-radius: 5px;

  .note-item {
    --padding-start: 0;
    padding: 10px 0;
  }

  .notes-message {
    h2 {
      font-weight: 600;
      font-size: 1rem;
    }

    p:nth-child(3) {
      font-size: 0.75rem;
    }
  }

  .notes-badge {
    font-size: 0.6rem;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notes-avatar {
    width: 40px;
    height: 40px;
  }
}

.notes-private-item {
  --padding-start: 0;
}

.notes-instruction {
  margin: 10px 0;
  padding: 1rem;
  background-color: rgb(239, 246, 255);
  color: rgb(29, 78, 216);
  border-radius: .375rem;
}
</style>
