
import {defineComponent, ref} from 'vue';
import {
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonList,
  IonListHeader,
  IonCheckbox, alertController,
} from '@ionic/vue';
import InputGroup from "@/components/InputGroup.vue";
import {useI18n} from "vue-i18n";
import {Moment} from "moment";
import Button from "@/components/Button.vue";
import ApiService from "@/services/api.service";
import Upload from "@/components/Upload.vue";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default defineComponent({
  name: 'ExerciseForm',
  components: {
    Upload,
    Button,
    InputGroup,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonList,
    IonListHeader,
    IonCheckbox,
    QuillEditor,
  },
  props: {
    patientId: {
      type: Number,
      required: true,
    },
    exerciseToUpdate: {
      type: Object,
      required: false,
    },
    availableDates: Array
  },
  setup() {
    const { t } = useI18n();

    const uploadRef = ref(Upload);

    return {
      t,
      uploadRef,
    }
  },
  data() {
    return {
      exercise: {
        title: '',
        description: '',
        instructions: {
          sessions: 1,
          sets: '',
          repetitions: '',
          time: '',
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_dates: [] as string[],
      },
      dates: this.availableDates,
    };
  },
  watch: {
    exerciseToUpdate: {
      deep: true,
      immediate: true,
      handler(exercise) {
        if (!exercise) {
          return;
        }

        this.exercise = {
          ...this.exercise,
          ...exercise,
          instructions: {
            sessions: Number(exercise.instructions.sessions),
            sets: exercise.instructions.sets ? Number(exercise.instructions.sets) : '',
            repetitions: exercise.instructions.repetitions ? Number(exercise.instructions.repetitions) : '',
            time: exercise.instructions.time ? Number(exercise.instructions.time) : '',
          }
        };
      }
    }
  },
  methods: {
    toggleDate(date: Moment) {
      const displayDate = date.format('D-M-Y')

      if (this.exercise.start_dates.includes(displayDate)) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.exercise.start_dates = this.exercise.start_dates.filter(item => item !== displayDate)
      } else {
        this.exercise.start_dates.push(displayDate);
      }
    },
    async submit() {
      const data = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        personal_patient_id: this.patientId,
        ...this.exercise,
        ...this.exercise.instructions,
        // eslint-disable-next-line @typescript-eslint/camelcase
        upload_identifiers: this.uploadRef.completedUploads.map((object: any) => {
          return object.fileId;
        })
      };

      if (this.exerciseToUpdate) {
        await ApiService.put(`/exercise/${this.exerciseToUpdate.id}`, data);

        this.$emit('exercise-updated');
      } else {
        await ApiService.post('/exercise', data);

        this.$emit('exercise-created');
      }
    },
    async deleteExercise() {
      if (!this.exerciseToUpdate) {
        return;
      }

      const alert = await alertController.create({
        header: this.t('areYouSure'),
        message: this.t('patients.deleteExerciseDescription'),
        buttons: [
          {
            text: this.t('no'),
            role: 'cancel',
          },
          {
            text: this.t('yes'),
            handler: async () => {
              await ApiService.delete(`/exercise/${this.exerciseToUpdate?.id}`);
              this.$emit('exercise-deleted');
            },
          },
        ],
      });

      await alert.present();
    }
  },
});
