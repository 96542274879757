import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58ec8f37"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-padding" }
const _hoisted_2 = { class: "ion-margin-bottom" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "ion-padding-horizontal" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_IonAvatar = _resolveComponent("IonAvatar")!
  const _component_IonSegmentButton = _resolveComponent("IonSegmentButton")!
  const _component_IonSegment = _resolveComponent("IonSegment")!
  const _component_IonLabel = _resolveComponent("IonLabel")!
  const _component_IonListHeader = _resolveComponent("IonListHeader")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_IonItem = _resolveComponent("IonItem")!
  const _component_IonList = _resolveComponent("IonList")!
  const _component_IonCardContent = _resolveComponent("IonCardContent")!
  const _component_IonCard = _resolveComponent("IonCard")!
  const _component_Notes = _resolveComponent("Notes")!
  const _component_IonCol = _resolveComponent("IonCol")!
  const _component_IonRow = _resolveComponent("IonRow")!
  const _component_IonIcon = _resolveComponent("IonIcon")!
  const _component_IonFabButton = _resolveComponent("IonFabButton")!
  const _component_IonFab = _resolveComponent("IonFab")!
  const _component_ExerciseForm = _resolveComponent("ExerciseForm")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_IonModal = _resolveComponent("IonModal")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_Upload = _resolveComponent("Upload")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, {
    "back-button": "/patients",
    name: _ctx.patient.full_name
  }, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_Loading, null, null, 512), [
        [_vShow, _ctx.loading]
      ]),
      _withDirectives(_createVNode(_component_IonRow, null, {
        default: _withCtx(() => [
          _createVNode(_component_IonCol, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_IonAvatar, { class: "mainAvatar" }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        loading: "lazy",
                        src: _ctx.patient.profile_picture
                      }, null, 8, _hoisted_3)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("h3", null, _toDisplayString(_ctx.patient.full_name), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_IonSegment, {
                  color: "light",
                  value: _ctx.tabToShow,
                  onIonChange: _ctx.tabChanged
                }, {
                  default: _withCtx(() => [
                    (_ctx.patient && _ctx.patient.permissions.View_own_exercises)
                      ? (_openBlock(), _createBlock(_component_IonSegmentButton, {
                          key: 0,
                          value: "exercises"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("patients.exercises")), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.getPermission('View_notepad') && _ctx.patient && _ctx.patient.permissions.View_notepad)
                      ? (_openBlock(), _createBlock(_component_IonSegmentButton, {
                          key: 1,
                          value: "notes"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("patients.notes")), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["value", "onIonChange"])
              ]),
              (_ctx.tabToShow === 'exercises')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_IonCard, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_IonCardContent, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_IonList, { class: "exercise-list" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_IonListHeader, { class: "ion-no-margin ion-no-padding" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_IonLabel, { class: "ion-no-padding ion-no-margin ion-margin-bottom" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.t("patients.exercises")), 1)
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                (_ctx.patient.exercises && _ctx.patient.exercises.length === 0)
                                  ? (_openBlock(), _createBlock(_component_Alert, {
                                      key: 0,
                                      message: _ctx.t('patients.noExercises')
                                    }, null, 8, ["message"]))
                                  : _createCommentVNode("", true),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.patient.exercises, (exercise, index) => {
                                  return (_openBlock(), _createBlock(_component_IonItem, {
                                    key: exercise.id,
                                    onClick: ($event: any) => (_ctx.editExercise(index)),
                                    class: "ion-no-padding ion-no-margin"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_IonAvatar, {
                                        slot: "start",
                                        class: "exercise-image"
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("img", {
                                            src: exercise.thumbnail
                                          }, null, 8, _hoisted_6)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_IonLabel, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(exercise.title), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                }), 128))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.getPermission('View_notepad') && _ctx.tabToShow === 'notes')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_Notes, {
                      title: _ctx.t('patients.notes'),
                      loading: _ctx.loadingNotes,
                      records: _ctx.notes
                    }, null, 8, ["title", "loading", "records"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512), [
        [_vShow, !_ctx.loading]
      ]),
      _createVNode(_component_IonFab, {
        vertical: "bottom",
        horizontal: "end",
        onClick: _ctx.open
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IonFabButton, null, {
            default: _withCtx(() => [
              _createVNode(_component_IonIcon, { icon: _ctx.addOutline }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_IonModal, {
        "is-open": _ctx.showNewExercise,
        onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openExerciseModal(false)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Modal, {
            onDismiss: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openExerciseModal(false))),
            title: _ctx.t('patients.addExercise')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ExerciseForm, {
                onExerciseCreated: _ctx.exerciseCreated,
                "patient-id": _ctx.patient.id,
                "available-dates": _ctx.dates
              }, null, 8, ["onExerciseCreated", "patient-id", "available-dates"])
            ]),
            _: 1
          }, 8, ["title"])
        ]),
        _: 1
      }, 8, ["is-open"]),
      _createVNode(_component_IonModal, {
        "is-open": _ctx.showEditExercise,
        onDidDismiss: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openEditExerciseModal(false)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Modal, {
            onDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openEditExerciseModal(false))),
            title: _ctx.t('patients.editExercise')
          }, {
            default: _withCtx(() => [
              (_ctx.exerciseData)
                ? (_openBlock(), _createBlock(_component_ExerciseForm, {
                    key: 0,
                    ref: "exerciseEditForm",
                    onExerciseUpdated: _ctx.exerciseUpdated,
                    onExerciseDeleted: _ctx.exerciseDeleted,
                    "patient-id": _ctx.patient.id,
                    "exercise-to-update": _ctx.exerciseData
                  }, null, 8, ["onExerciseUpdated", "onExerciseDeleted", "patient-id", "exercise-to-update"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["title"])
        ]),
        _: 1
      }, 8, ["is-open"]),
      _createVNode(_component_IonModal, {
        "is-open": _ctx.showNotesModal,
        onDidDismiss: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openNotesModal(false)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Modal, {
            onDismiss: _cache[6] || (_cache[6] = ($event: any) => (_ctx.openNotesModal(false))),
            title: _ctx.t('notes.newMessage')
          }, {
            default: _withCtx(() => [
              _createElementVNode("form", {
                onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendMessage && _ctx.sendMessage(...args)), ["prevent"]))
              }, [
                _createVNode(_component_InputGroup, {
                  modelValue: _ctx.newNote,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newNote) = $event)),
                  type: "textarea",
                  rows: "10",
                  color: "primary",
                  placeholder: _ctx.t('notes.message')
                }, null, 8, ["modelValue", "placeholder"]),
                _createVNode(_component_Upload, { ref: "uploadRef" }, null, 512),
                _createVNode(_component_Button, {
                  expand: "full",
                  type: "submit",
                  color: "success"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('send')), 1)
                  ]),
                  _: 1
                })
              ], 32)
            ]),
            _: 1
          }, 8, ["title"])
        ]),
        _: 1
      }, 8, ["is-open"])
    ]),
    _: 1
  }, 8, ["name"]))
}