import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_IonLabel = _resolveComponent("IonLabel")!
  const _component_IonItem = _resolveComponent("IonItem")!
  const _component_QuillEditor = _resolveComponent("QuillEditor")!
  const _component_Upload = _resolveComponent("Upload")!
  const _component_IonSelectOption = _resolveComponent("IonSelectOption")!
  const _component_IonSelect = _resolveComponent("IonSelect")!
  const _component_IonListHeader = _resolveComponent("IonListHeader")!
  const _component_IonCheckbox = _resolveComponent("IonCheckbox")!
  const _component_IonList = _resolveComponent("IonList")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_InputGroup, {
      modelValue: _ctx.exercise.title,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.exercise.title) = $event)),
      color: "primary",
      label: _ctx.t('patients.exerciseName')
    }, null, 8, ["modelValue", "label"]),
    _createVNode(_component_IonItem, {
      class: "ion-no-padding",
      lines: "none"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_IonLabel, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('patients.exerciseDescription')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_QuillEditor, {
      content: _ctx.exercise.description,
      "onUpdate:content": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.exercise.description) = $event)),
      contentType: "html",
      theme: "snow"
    }, null, 8, ["content"]),
    _createVNode(_component_Upload, {
      ref: "uploadRef",
      "multiple-files": false
    }, null, 512),
    _createVNode(_component_IonItem, { class: "ion-no-padding" }, {
      default: _withCtx(() => [
        _createVNode(_component_IonLabel, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('patients.sessionsPerDay')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_IonSelect, {
          modelValue: _ctx.exercise.instructions.sessions,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.exercise.instructions.sessions) = $event))
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1,2,3,4,5], (session) => {
              return _createVNode(_component_IonSelectOption, {
                key: session,
                value: session
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('patients.sessions', session)), 1)
                ]),
                _: 2
              }, 1032, ["value"])
            }), 64))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_IonItem, { class: "ion-no-padding" }, {
      default: _withCtx(() => [
        _createVNode(_component_IonLabel, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('patients.sets')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_IonSelect, {
          modelValue: _ctx.exercise.instructions.sets,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.exercise.instructions.sets) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IonSelectOption, { value: "" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('patients.noIndication')), 1)
              ]),
              _: 1
            }),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1,2,3,4,5,6,7,8,9,10], (set) => {
              return _createVNode(_component_IonSelectOption, {
                key: set,
                value: set
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('patients.setsOption', set)), 1)
                ]),
                _: 2
              }, 1032, ["value"])
            }), 64))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_IonItem, { class: "ion-no-padding" }, {
      default: _withCtx(() => [
        _createVNode(_component_IonLabel, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('patients.repetitions')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_IonSelect, {
          modelValue: _ctx.exercise.instructions.repetitions,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.exercise.instructions.repetitions) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IonSelectOption, { value: "" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('patients.noIndication')), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...Array(50).keys()], (repetition) => {
              return (_openBlock(), _createBlock(_component_IonSelectOption, {
                key: repetition,
                value: repetition+1
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('patients.repetitionsOption', repetition+1)), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_IonItem, { class: "ion-no-padding" }, {
      default: _withCtx(() => [
        _createVNode(_component_IonLabel, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('patients.time')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_IonSelect, {
          modelValue: _ctx.exercise.instructions.time,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.exercise.instructions.time) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IonSelectOption, { value: "" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('patients.noIndication')), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([[0.08, 5], [0.16, 10], [0.5, 30]], ([seconds, display]) => {
              return (_openBlock(), _createBlock(_component_IonSelectOption, {
                key: seconds,
                value: seconds
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('patients.secondsOption', display)), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128)),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList([1,2,3,4,5,6,7,8,9,10], (minutes) => {
              return _createVNode(_component_IonSelectOption, {
                key: minutes,
                value: minutes
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('patients.minutesOption', minutes)), 1)
                ]),
                _: 2
              }, 1032, ["value"])
            }), 64))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }),
    (!_ctx.exerciseToUpdate)
      ? (_openBlock(), _createBlock(_component_IonList, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_IonListHeader, { class: "ion-no-padding" }, {
              default: _withCtx(() => [
                _createVNode(_component_IonLabel, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('patients.planDirect')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dates, (date) => {
              return (_openBlock(), _createBlock(_component_IonItem, {
                key: date,
                class: "ion-no-padding"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_IonLabel, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(date.format('dddd D MMMM')), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_IonCheckbox, {
                    onIonChange: ($event: any) => (_ctx.toggleDate(date)),
                    slot: "start"
                  }, null, 8, ["onIonChange"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_Button, {
      expand: "full",
      onClick: _ctx.submit,
      color: "success"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t(_ctx.exerciseToUpdate ? 'patients.editExercise' : 'patients.addExercise')), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    (_ctx.exerciseToUpdate)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 1,
          color: "danger",
          expand: "full",
          onClick: _ctx.deleteExercise,
          class: "delete-btn"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t('patients.deleteExercise')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}